import { base64ToBlob } from './base64ToBlob';

export const DB_NAME = 'ies-offline-db';
const DB_VERSION = 1; //If the schema of the object store change, this dbVersion need to be increased
export const OBJECT_STORE_NAME = 'installations';

// Utility functions
export const isIndexedDBSupported = (): Boolean => {
  const isSupported = window.indexedDB;
  if (!isSupported) {
    console.error('Browser does not support index db');
  }
  return Boolean(isSupported);
};

export const initialiseIndexedDB = (): (() => Promise<IDBDatabase>) => {
  let dbInstance: IDBDatabase | undefined;

  return async () => {
    if (dbInstance) return dbInstance;

    dbInstance = await setupIndexedDB();
    return dbInstance;
  };
};

export enum storedIndexedDBObjectType {
  SYNC_DATA,
  SYNC_POINT,
  NETWORK,
  DEVIATIONS,
  DEVIATIONS_LOCK,
  DEVIATIONS_TO_SYNC,
  HAS_DATA_TO_SYNC,
  HANDOVER_DETAIL,
}

export const generateIndexedDBKey = (
  networkNumber: string | undefined,
  objectType: storedIndexedDBObjectType
): string => {
  switch (objectType) {
    case storedIndexedDBObjectType.SYNC_DATA:
      return `${networkNumber}_syncdata`;

    case storedIndexedDBObjectType.SYNC_POINT:
      return `${networkNumber}_syncpoint`;

    case storedIndexedDBObjectType.NETWORK:
      return `${networkNumber}_network`;

    case storedIndexedDBObjectType.DEVIATIONS:
      return `${networkNumber}_deviations`;

    case storedIndexedDBObjectType.DEVIATIONS_LOCK:
      return `${networkNumber}_deviations_lock`;

    case storedIndexedDBObjectType.DEVIATIONS_TO_SYNC:
      return `${networkNumber}_deviations_to_sync`;

    case storedIndexedDBObjectType.HAS_DATA_TO_SYNC:
      return `${networkNumber}_has_data_to_sync`;

    // This one has nothing to do with offline mode, it use to keep handover information even if user close the tab
    case storedIndexedDBObjectType.HANDOVER_DETAIL:
      return `${networkNumber}_handover_detail`;

    default:
      throw new Error('invalid object type, cannot generate indexedDB key');
  }
};
// End Utility functions

//Initialization & caching the dbInstance
const getDbInstance = initialiseIndexedDB();

const createObjectStore = (
  dbInstance: IDBDatabase,
  objectStoreName: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const createStoreRequest = dbInstance.createObjectStore(objectStoreName);
    createStoreRequest.transaction.oncomplete = () => {
      return resolve();
    };
    createStoreRequest.transaction.onabort = () => {
      return reject();
    };
  });
};

const setupIndexedDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const openDbConnectionRequest = indexedDB.open(DB_NAME, DB_VERSION);
    /* This event will be triggered if create a new db or increase the db version
       If this event ends successfully, the onsuccess event (below) will be triggered
    */
    openDbConnectionRequest.onupgradeneeded = async () => {
      const db = openDbConnectionRequest.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        await createObjectStore(db, OBJECT_STORE_NAME);
      }
    };

    openDbConnectionRequest.onsuccess = () => {
      resolve(openDbConnectionRequest.result);
    };

    openDbConnectionRequest.onerror = () => {
      reject('cannot open connection with IndexedDB');
    };
  });
};
/// get the data based on the questionSequence for particular network
export const getIndexedDBObject = async <T>(key: string): Promise<T | undefined> => {
  const dbInstance = await getDbInstance();

  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME, 'readonly');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    const getRequest = installationsStore.get(key);

    getRequest.onsuccess = () => {
      resolve(getRequest.result);
    };
    transaction.onabort = () => {
      reject('Transaction aborted while trying to get object');
    };
  });
};
export const getIndexedDBBlobObject = async <T>(
  key: string,
  questionSetId: string,
  questionSequence: number,
  filename: string
): Promise<T | undefined | any> => {
  const dbInstance = await getDbInstance();

  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME, 'readonly');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    const getRequest = installationsStore.get(key);

    getRequest.onsuccess = () => {
      const result = getRequest.result;
      for (const testerAnswer in result.testerAnswers) {
        if (result.testerAnswers[testerAnswer].questionSetId === questionSetId) {
          const answers = result.testerAnswers[testerAnswer].answers;
          const filterd = answers.find(
            (answer: any) => answer && answer.questionSequenceNumber === questionSequence
          );
          const filterBlob = filterd.value.find(
            (value: any) => value.filename === filename
          );
          const blobO = base64ToBlob(
            filterBlob.blob.base64Data,
            filterBlob.blob.mimeType
          );
          resolve(blobO);
        }
      }
    };
    transaction.onabort = () => {
      reject('Transaction aborted while trying to get object');
    };
  });
};

export const getAllIndexDbKeys = async (): Promise<IDBValidKey[]> => {
  const dbInstance = await getDbInstance();
  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME);
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    const getRequest = installationsStore.getAllKeys();

    getRequest.onsuccess = () => {
      resolve(getRequest.result);
    };
    transaction.onabort = () => {
      reject('Transaction aborted while trying to get all keys');
    };
  });
};

/**
 * Update or insert a object to indexedDB.

 * @param newObject {Object} a COMPLETE object to be insert or update
 * @param key {string} a unique key that object associates with in the db
 */
export const upsertIndexedDBObject = async <T>(
  newObject: T,
  key: string
): Promise<void> => {
  const dbInstance = await getDbInstance();
  const objectFromDB = await getIndexedDBObject(key);

  return new Promise((resolve, reject) => {
    const transaction = dbInstance?.transaction(OBJECT_STORE_NAME, 'readwrite');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    if (objectFromDB) {
      installationsStore.put(newObject, key);
    } else {
      installationsStore.add(newObject, key);
    }

    transaction.oncomplete = () => {
      resolve();
    };
    transaction.onabort = () => {
      reject('Transaction aborted while trying to upsert object');
    };
  });
};

/** Deletes the specefic data according to the key.  */
export const deleteIndexedDBObject = async (key: string): Promise<void> => {
  const dbInstance = await getDbInstance();

  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME, 'readwrite');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    const deleteRequest = installationsStore.delete(key);

    deleteRequest.onsuccess = () => {
      resolve();
    };
    transaction.onabort = () => {
      reject('Transaction aborted while trying to delete object');
    };
  });
};

/**Deletes all the data for a related network(deletes network, sync data, sync point data). */
export const truncateNetworkFromIndexedDB = async (
  networkNumber: string
): Promise<void> => {
  const dbInstance = await getDbInstance();

  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME, 'readwrite');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);

    const networkIndexedDBKeys = [
      storedIndexedDBObjectType.SYNC_DATA,
      storedIndexedDBObjectType.SYNC_POINT,
      storedIndexedDBObjectType.NETWORK,
      storedIndexedDBObjectType.HAS_DATA_TO_SYNC,
      storedIndexedDBObjectType.DEVIATIONS,
      storedIndexedDBObjectType.DEVIATIONS_LOCK,
      storedIndexedDBObjectType.DEVIATIONS_TO_SYNC,
      storedIndexedDBObjectType.HANDOVER_DETAIL,
    ].map((keyType) => generateIndexedDBKey(networkNumber, keyType));

    networkIndexedDBKeys.forEach((key) => {
      installationsStore.delete(key);
    });

    transaction.oncomplete = () => {
      resolve();
    };
    transaction.onabort = () => {
      reject('Transaction aborted while clearing network from objectStore in indexedDB');
    };
  });
};

/** Clears the database by removing all of the stored data in the IndexedDB object store.  */
export const clearIndexedDB = async (): Promise<void> => {
  const dbInstance = await getDbInstance();

  return new Promise((resolve, reject) => {
    const transaction = dbInstance.transaction(OBJECT_STORE_NAME, 'readwrite');
    const installationsStore = transaction.objectStore(OBJECT_STORE_NAME);
    const clearRequest = installationsStore.clear();

    clearRequest.onsuccess = () => resolve();
    transaction.onabort = () =>
      reject(
        `Transaction aborted while trying to clear object store ${OBJECT_STORE_NAME}`
      );
  });
};

import axios from 'axios';
import type { Attachment } from '../schemas';
import { API_TYPE, post } from './fetch';

type UploadParams = {
  files: File[];
  networkNumber: string;
  questionSequenceNumber: number | string;
  questionSetIdParam: string | null;
  jwtToken: string;
};

type UploadDeviationsParams = {
  files: File[];
  networkNumber: string;
  jwtToken: string;
};

type DownloadParams = {
  files: Attachment[];
  jwtToken: string;
};

type SignatureResponse = {
  signedUrl: string;
  originalFilename: string;
  locationFilename: string;
};

export enum FileType {
  ATTACHMENT,
  DEVIATION,
}

export const getDownloadSignedUrlForAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post('v1/attachments/download', jwtToken, API_TYPE.APPLICATION, payload);
};

export const getDownloadSignedUrlForDeviationsAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post(
    'v1/deviations/files/downloads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForAttachment = async (
  params: UploadParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, questionSequenceNumber, questionSetIdParam, jwtToken } =
    params;
  const payload = {
    filenames: files.map((file) => file.name),
  };
  return await post(
    `v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForDeviationsAttachment = async (
  params: UploadDeviationsParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, jwtToken } = params;
  const payload = {
    filenames: files.map((file) => file.name),
    networkNumber,
  };
  return await post(
    'v1/deviations/files/uploads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const uploadAttachments = async (
  params: UploadParams | UploadDeviationsParams,
  fileType = FileType.ATTACHMENT
): Promise<Attachment[] | undefined> => {
  const { files, networkNumber, questionSequenceNumber, questionSetIdParam } =
    params as UploadParams;
  const attachmentParam: Attachment[] = [];
  const uploadFiles: any[][] = [];
  try {
    const convertBlobToBase64 = (blob: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = (reader.result as string).toString().split(',')[1];
          resolve(base64data);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    if (Array.isArray(files)) {
      const fileArray = files;
      const fileDataPromises = fileArray.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            if (file instanceof File) {
              if (reader.result) {
                const blob = new Blob([reader.result], { type: MimeType.toString() });
                try {
                  const base64data = await convertBlobToBase64(blob);
                  resolve({
                    fileName: file.name,
                    base64Data: base64data,
                    mimeType: MimeType.toString(),
                  });
                } catch (error) {
                  reject(error);
                }
              }
            }
          };
          reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
        });
      });
      Promise.all(fileDataPromises).then((filesData) => {
        uploadFiles.push(filesData);
      });
    }

    const responseSignedURLList =
      fileType === FileType.ATTACHMENT
        ? await getUploadSignedUrlForAttachment(params as UploadParams)
        : await getUploadSignedUrlForDeviationsAttachment(
            params as UploadDeviationsParams
          );
    if (navigator.onLine && responseSignedURLList && responseSignedURLList.length > 0) {
      await Promise.all(
        responseSignedURLList.map(async (response: SignatureResponse, index: number) => {
          const fileBlob = uploadFiles[index];
          attachmentParam.push({
            filename: response.originalFilename,
            location: response.locationFilename,
            blob: fileBlob,
          });
          return axios.put(`${response.signedUrl}`, files[index]);
        })
      );
    } else {
      //if sync happens no need to have this else part as sync will handle offline case
      await Promise.all(
        uploadFiles.map(async (fileData: any, index: number) => {
          const fileBlob = uploadFiles[index];
          fileBlob.map((fileB: any) => {
            attachmentParam.push({
              filename: fileB.fileName,
              location: `${networkNumber}/${questionSetIdParam}/${questionSequenceNumber}/${fileB.fileName}`,
              blob: fileB,
            });
          });
        })
      );
    }
  } catch (e) {
    console.error('Failed to upload attachments', e);
  }
  return attachmentParam.length > 0 ? attachmentParam : undefined;
};

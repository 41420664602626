import React, { useState } from 'react';
import { uniqBy } from 'lodash';
import {
  ActivityDifferentiator,
  AnswerSet,
  AnswerValue,
  Attachment,
  Deviation,
  Installation,
  Question,
  QuestionSet,
  QuestionType,
  QuestionValueType,
  SpecialUserIds,
} from '../../schemas';
import Box from '@material-ui/core/Box';
import {
  AppBar,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { theme } from '@konecorp/ui-library';
import logo from '../../logo.svg';
import { useTranslation } from 'react-i18next';
import { skipQuestion } from '../../helpers/question';
import { getLocalizedText } from '../../helpers/custom-i18n';
import { i18n } from 'i18next';
import CustomizedLocalizedQuestionText from '../CustomizedLocalizedQuestionText';
import PreviewFile from '../PreviewFile';
import { FileType } from '../../helpers/upload-download';

export type QuestionSummaryProps = {
  questionSets: QuestionSet[];
  answerSets: AnswerSet[];
  networkNumber: string;
  supervisorName: string;
  installationData: Installation | null;
  openDeviations: Deviation[];
};

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
    toolbar: {
      textAlign: 'center',
    },
    title: {
      flex: 1,
      flexGrow: 1,
    },
    faultyRow: {
      color: theme.palette.error.main,
    },
    link: {
      color: 'blue',
      textDecoration: 'none',
      fontWeight: 'bold',
      hover: {
        color: 'red',
      },

      // color: blue; /* Default color */
      // text-decoration: none; /* Remove underline */
      // font-weight: bold; /* Default font-weight */
      // /* Add other styles as needed */
    },
  })
);

export type summaryTableData = {
  key: string;
  question?: string;
  floor: string | false | null;
  answer?: string | JSX.Element[];
  employeeID?: string;
  isFaulty: boolean;
};

const isAnswerFaulty = (
  question: Question,
  value: AnswerValue | undefined,
  hasDeviations: boolean
) => {
  if (typeof value === 'number') {
    const { negativeTolerance, positiveTolerance, actualValue } = question;
    const minValue = Number(actualValue) - Number(negativeTolerance);
    const maxValue = Number(actualValue) + Number(positiveTolerance);

    return value < minValue || value > maxValue;
  }

  if (typeof value === 'boolean') {
    return hasDeviations;
  }
  if (question.valueType === QuestionValueType.PICTURE) {
    return Array.isArray(value) && value.length === 0;
  }

  return value === undefined || value === null;
};

const QuestionSummaryTable = ({
  networkNumber,
  installationData,
  questionSets,
  answerSets,
  supervisorName,
  openDeviations,
}: QuestionSummaryProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [openAttachment, setOpenAttachment] = useState<Attachment>();
  const formatAnswer = (
    currentQuestion: Question,
    value: AnswerValue | undefined,
    hasDeviations: boolean,
    i18n: i18n
  ) => {
    if (currentQuestion.valueType === QuestionValueType.PICTURE) {
      if (Array.isArray(value) && value.length > 0) {
        return (value as Attachment[]).map((attachment: Attachment, index: number) => (
          <Link
            key={index}
            style={{ color: 'black' }}
            onClick={() => setOpenAttachment(attachment)}
          >
            {attachment.filename}{' '}
          </Link>
        ));
      }
    }
    if (typeof value === 'number') {
      return currentQuestion.actualValue
        ? `${value} (${currentQuestion.actualValue})`
        : `${value}`;
    }
    if (
      currentQuestion.valueType === QuestionValueType.YES_NO_CHOICE &&
      typeof value === 'boolean'
    ) {
      return !value ? i18n.t('reviewForm.notOK') : i18n.t('reviewForm.OK');
    }
    if (typeof value === 'boolean') {
      return hasDeviations ? i18n.t('reviewForm.notOK') : i18n.t('reviewForm.OK');
    }
    return value?.toString();
  };
  const getSummaryTableData = (
    questionSets: QuestionSet[],
    answerSets: AnswerSet[],
    deviations: Deviation[],
    i18n: i18n
  ) => {
    const questionSummaryTableData: summaryTableData[] = [];
    for (const quesSet of questionSets) {
      const setId = quesSet.questionSetId;
      const answerSet = answerSets.find((set) => set.questionSetId === setId);
      if (answerSet === undefined) break;
      const quesSetQuestions = quesSet.questions;
      const ansSetAnswers = answerSet.answers || [];
      for (let index = 0; index < quesSetQuestions.length; index++) {
        const currentQuestion = quesSetQuestions[index];
        const isNotSkippedQuestion = !skipQuestion(currentQuestion.condition, answerSets);
        const isNotDummyQuestion = currentQuestion.questionType !== QuestionType.DUMMY;
        if (isNotSkippedQuestion && isNotDummyQuestion) {
          const questionText = currentQuestion.text
            ? getLocalizedText(i18n, currentQuestion.text)?.text
            : '';
          const floorInformation =
            currentQuestion.valueType?.toLowerCase() !== QuestionValueType.OK &&
            Number(currentQuestion.quantityRule) > 1 &&
            currentQuestion.position;
          const answerHasDeviations = deviations.some(
            ({ questionSetId, questionSequence }) =>
              questionSetId === setId && questionSequence === index
          );
          const answerText = formatAnswer(
            currentQuestion,
            ansSetAnswers[index]?.value,
            answerHasDeviations,
            i18n
          );
          const isFaulty = isAnswerFaulty(
            currentQuestion,
            ansSetAnswers[index]?.value,
            answerHasDeviations
          );
          questionSummaryTableData.push({
            key: `${quesSet.questionSetId}-${currentQuestion.tag}-${index}`,
            employeeID: ansSetAnswers[index]?.answerer,
            floor: floorInformation,
            question: questionText,
            answer: answerText,
            isFaulty,
          });
        }
      }
    }
    return questionSummaryTableData;
  };

  const questionSummaryTableData: summaryTableData[] = getSummaryTableData(
    questionSets,
    answerSets,
    openDeviations,
    i18n
  );
  const equipmentNumber = installationData?.equipmentNumber || '';
  const flOrganisation = installationData?.customer?.countryKey || '';
  const address = installationData?.salesOrderDescription || '';
  const classes = useStyles(theme);

  const subcontractors = uniqBy(
    installationData?.subcontractors || [],
    'activityDifferentiator'
  );

  const getName = (employeeId?: string) => {
    const findSubcontractor = (role: ActivityDifferentiator) =>
      subcontractors.find(
        (subcontractor) => subcontractor.activityDifferentiator === role
      )?.subcontractor;

    switch (employeeId) {
      case SpecialUserIds.SUBCONTRACTOR_INSTALLER: {
        const subcontractor = findSubcontractor(ActivityDifferentiator.INST);
        return subcontractor?.name || t('deviationChangeHistory.subcontractor');
      }
      case SpecialUserIds.SUBCONTRACTOR_TESTER: {
        const subcontractor = findSubcontractor(ActivityDifferentiator.CMSN);
        return subcontractor?.name || t('deviationChangeHistory.subcontractor');
      }
      default:
        return employeeId;
    }
  };

  return (
    <>
      <Box pt={6}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Box pl={2} className={classes.title}>
              <Typography variant="h6">
                {t('reviewForm.equipment')}: {equipmentNumber}
              </Typography>
            </Box>
            {logo && <img src={logo} />}
          </Toolbar>
        </AppBar>
      </Box>
      <Box pl={2} pr={2} pt={2}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography>{t('reviewForm.networkNumber')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div">
              <Box fontWeight="bold">{networkNumber}</Box>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{t('reviewForm.supervisorName')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div">
              <Box fontWeight="bold">{supervisorName}</Box>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{t('reviewForm.flOrganisation')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div">
              <Box fontWeight="bold">{flOrganisation}</Box>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{t('reviewForm.address')}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="div">
              <Box fontWeight="bold">{address}</Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('reviewForm.tasks')}</TableCell>
              <TableCell align="right">{t('reviewForm.floor')}</TableCell>
              <TableCell align="right">
                {t('reviewForm.answer')} ({t('reviewForm.target')})
              </TableCell>
              <TableCell align="right">{t('reviewForm.empId')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionSummaryTableData.map((row) => (
              <TableRow key={row.key}>
                <TableCell align="left">
                  <CustomizedLocalizedQuestionText
                    localaizedQuestionText={row.question || ''}
                    typographyVariant="body2"
                  />
                </TableCell>
                <TableCell align="right">{row.floor}</TableCell>
                <TableCell
                  align="right"
                  data-testid="question-summary-row-answer"
                  style={{ color: row.isFaulty ? 'red' : '' }}
                >
                  {row.answer || t('reviewForm.unanswered')}
                </TableCell>
                <TableCell align="right">{getName(row.employeeID)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openAttachment && (
        <Dialog open={true} onClose={() => setOpenAttachment(undefined)}>
          <DialogContent>
            <DialogContentText data-testid="file-preview-description">
              {openAttachment.filename}
            </DialogContentText>
            <PreviewFile file={openAttachment} fileType={FileType.ATTACHMENT} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default QuestionSummaryTable;

import { API_TYPE, put } from './fetch';
import {
  ActivityDifferentiator,
  InstallationSyncData,
  Syncpoint,
  Installation,
  Attachment,
} from '../schemas';
import {
  generateIndexedDBKey,
  storedIndexedDBObjectType,
  getIndexedDBObject,
  upsertIndexedDBObject,
  deleteIndexedDBObject,
} from './indexedDB';
import { base64ToBlob } from './base64ToBlob';

export const hasDataToSync = async (networkNumber: string): Promise<boolean> => {
  const pending = await getIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
  return Boolean(pending);
};

export const setHasDataToSync = async (networkNumber: string): Promise<void> => {
  await upsertIndexedDBObject(
    true,
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

const removeHasDataToSync = async (networkNumber: string): Promise<void> => {
  await deleteIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

const APIGW_URL = process.env.REACT_APP_APIGW_URL || '';
export const syncWithBackend = async (
  networkNumber: string,
  jwtToken: string
): Promise<void> => {
  try {
    const syncDataKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_DATA
    );
    // const syncPointKey = generateIndexedDBKey(
    //   networkNumber,
    //   storedIndexedDBObjectType.SYNC_POINT
    // );

    const syncDatafromIdxDb = await getIndexedDBObject<InstallationSyncData>(syncDataKey);
    if (syncDatafromIdxDb) {
      /* the syncWithBackend function will be call inside the service worker
          and axios unfortunately doesn't work with service worker. Hence, using
          native fetch over put helper (which leverage axios under the hood)
      */
      // Step - 1:Getting the file name to call the generateSignedurlfunction form upload-download.ts
      // Step - 2 Call axios endpoint with the payload passsing the file
      const newdata = await getImageData(syncDatafromIdxDb, jwtToken, networkNumber);
      console.log('newsyncDatafromIdxDb:::', newdata);
      // const requestOptions = {
      //   method: 'PUT',
      //   headers: {
      //     Authorization: jwtToken,
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ ...newdata }),
      // };
      // console.log('requestOptions:::', requestOptions.body);
      // const response = await fetch(
      //   `${APIGW_URL}/v1/installations/${networkNumber}/sync`,
      //   requestOptions
      // );

      // const syncPoint = await response.json();
      // if (syncPoint) {
      //   await upsertIndexedDBObject(syncPoint, syncPointKey);
      //   await removeHasDataToSync(networkNumber);
      // }
    }
  } catch (error) {
    throw new Error(`Error while syncing with backend ${error}`);
  }
};

export const cacheNetworkWithInstallationData = async (
  installationData: Installation,
  networkNumber: string,
  jwtToken: string,
  userRole: ActivityDifferentiator
): Promise<void> => {
  const installationDatakey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.NETWORK
  );
  await upsertIndexedDBObject(installationData, installationDatakey);
  const syncpointResponse: Syncpoint = await put(
    `v1/installations/${networkNumber}/syncpoint`,
    jwtToken,
    API_TYPE.APPLICATION,
    {
      userRole: userRole,
    }
  );
  return saveSyncpointindexDB(networkNumber, syncpointResponse);
};

const saveSyncpointindexDB = async (
  networkNumber: string,
  syncpoint: Syncpoint
): Promise<void> => {
  const syncpointkey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.SYNC_POINT
  );
  return await upsertIndexedDBObject(syncpoint, syncpointkey);
};

const getImageData = async (
  syncDatafromIdxDb: any,
  jwtToken: any,
  networkNumber: any
) => {
  // const [updateAnswerInContext] = useUpdateAnswerInContext();
  const attachmentParam: Attachment[] = [];
  try {
    const syncPointKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_POINT
    );
    if (typeof syncDatafromIdxDb === 'object' && syncDatafromIdxDb !== null) {
      const newsyncDatafromIdxDb = { ...syncDatafromIdxDb }; // Create a copy of the object

      if (Array.isArray(newsyncDatafromIdxDb.answers)) {
        // Use map and Promise.all to handle asynchronous operations
        await Promise.all(
          newsyncDatafromIdxDb.answers.map(async (answer: any) => {
            if (typeof answer === 'object' && answer !== null && 'value' in answer) {
              if (Array.isArray(answer.value)) {
                await Promise.all(
                  answer.value.map(async (item: any, index: any) => {
                    if (typeof item === 'object' && item !== null && 'filename' in item) {
                      console.log(`Processing item at position ${index}:`, item);

                      try {
                        // Validate item structure
                        if (!item.blob) {
                          throw new Error(
                            `Invalid blob structure for item at position ${index}`
                          );
                        }

                        // Convert base64 data to blob
                        const blob = base64ToBlob(
                          item.blob.base64Data,
                          item.blob.mimeType
                        );
                        if (!(blob instanceof Blob)) {
                          throw new Error('base64ToBlob did not return a valid Blob');
                        }

                        // Get upload signed URL
                        const filenames = item.blob.fileName;
                        const resp = await getUploadSignedUrl(
                          filenames,
                          jwtToken,
                          networkNumber,
                          answer.questionSetId,
                          answer.questionSequenceNumber
                        );

                        // const requestOptions = {
                        //   method: 'POST',
                        //   headers: {
                        //     Authorization: jwtToken,
                        //     'Content-Type': 'application/json',
                        //   },
                        //   body: JSON.stringify({ filenames: item.blob.fileName }),
                        // };
                        // const resp = await fetch(
                        //   `${APIGW_URL}/v1/attachments/${networkNumber}/${
                        //     answer.questionSetId
                        //   }/${Number(answer.questionSequenceNumber)}/upload`,
                        //   requestOptions
                        // );
                        if (!resp.ok) {
                          throw new Error(
                            `Failed to fetch signed URL, status: ${resp.status}`
                          );
                        }
                        const data = await resp.json();
                        console.log('resp:::', data);

                        // Validate response
                        if (
                          !Array.isArray(data) ||
                          data.length === 0 ||
                          !data[0].signedUrl ||
                          !data[0].originalFilename ||
                          !data[0].locationFilename
                        ) {
                          throw new Error('Invalid response from getUploadSignedUrl');
                        }

                        if (typeof data[0].signedUrl === 'string') {
                          const requestOptions = {
                            method: 'PUT',
                            body: blob,
                          };
                          await fetch(`${data[0].signedUrl}`, requestOptions);
                          // await axios.put(data[0].signedUrl, blob);
                        } else {
                          console.error('signedUrl is not a string');
                        }
                        // Update the item with new values from response
                        item.filename = data[0].originalFilename;
                        item.location = data[0].locationFilename;

                        // Push updated item to attachmentParam
                        attachmentParam.push({
                          filename: item.filename,
                          location: item.location,
                          blob: blob,
                        });
                        console.log('Response from getUploadSignedUrl:', resp);
                      } catch (error) {
                        console.error(
                          `Error processing item at position ${index}:`,
                          error
                        );
                      }
                    } else {
                      console.log(
                        `Item at position ${index} is not valid or missing 'filename':`,
                        item
                      );
                    }
                  })
                );
              }
            }
            console.log(
              'newsyncDatafromIdxDb::: inside get image before return',
              newsyncDatafromIdxDb
            );
          })
        );

        // Proceed with the updated newsyncDatafromIdxDb
        const requestOptionsforSync = {
          method: 'PUT',
          headers: {
            Authorization: jwtToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newsyncDatafromIdxDb),
        };
        try {
          const syncDataKey = generateIndexedDBKey(
            networkNumber,
            storedIndexedDBObjectType.NETWORK
          );
          const response = await fetch(
            `${APIGW_URL}/v1/installations/${networkNumber}/sync`,
            requestOptionsforSync
          );
          if (!response.ok) {
            throw new Error(`Failed to sync data, status: ${response.status}`);
          }

          const syncPoint = await response.json();
          const requestOptions = {
            method: 'GET',
            headers: {
              Authorization: jwtToken,
              'Content-Type': 'application/json',
            },
          };
          if (syncPoint) {
            await upsertIndexedDBObject(syncPoint, syncPointKey);
            await upsertIndexedDBObject(newsyncDatafromIdxDb, syncDataKey);
            await removeHasDataToSync(networkNumber);
            deleteIndexedDBObject(syncDataKey);
            const response = await fetch(
              `${APIGW_URL}/v1/installations/${networkNumber}`,
              requestOptions
            );
            if (!response.ok) {
              throw new Error(`Failed to GET N/W data, status: ${response.status}`);
            }
            window.location.reload();
          }
        } catch (error) {
          console.error('Error during sync process:', error);
        }

        return newsyncDatafromIdxDb;
      }
    }
    return false;
  } catch (e) {
    console.log('error:::', e);
  }

  // The object does not meet the condition
};
const getUploadSignedUrl = async (
  filenames: any,
  jwttoken: any,
  networkNumber: any,
  questionSetIdParam: any,
  questionSequenceNumber: any
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: jwttoken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ filenames: filenames }),
  };
  return await fetch(
    `${APIGW_URL}/v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    requestOptions
  );
};
